import { lazy } from "react";
import axios from "axios";

import { createRoot } from "react-dom/client";

axios.defaults.baseURL = API_BASE_URL;

const container = document.getElementById("react-root");
const root = createRoot(container!); // https://react.dev/blog/2022/03/08/react-18-upgrade-guide#updates-to-client-rendering-apis

const App = lazy(() => import("./App"));

root.render(<App />);
